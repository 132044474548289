import React from "react";
import styles from "./Dialogs.module.scss";
import { Close } from "@material-ui/icons";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Modal,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";
import NoRecord from "../../assets/noRecord.svg";
import axios from "axios";
import UrlLinks from "../../UrlLinks";

export default function ProfileVerification(props) {
  const {
    uniqueKey,
    open,
    setOpen,
    title,
    dataList,
    leftPicName,
    rightPicName,
    leftPicLink,
    rightPicLink,
    showToast,
    updateMyData,
  } = props;

  const { users } = useSelector((state) => state);
  const { modalLoader } = users;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 698,
    height: 488,
    overflow: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    filter: "drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.15))",
    borderRadius: "5px",
  };

  const handleClose = () => setOpen(false);

  const handleClick = async (e, verdictKey) => {
    let mainUrl = "";
    if (uniqueKey === "profileVerification") {
      mainUrl = `${UrlLinks.baseUrl}${UrlLinks.verificationAPIs.getPhotoVerificationListing}?userId=${dataList.userId}`;
    } else {
      mainUrl = `${UrlLinks.baseUrl}${UrlLinks.projectListing.getAttendanceSelfieVerificationListing}?attendanceId=${dataList.attendanceId}`;
    }
    await axios
      .get(
        `${mainUrl}&verdict=${verdictKey}&verifiedBy=${localStorage.getItem(
          "userID"
        )}`
      )
      .then((res) => {
        showToast(
          "success",
          `${verdictKey === 1 ? "Verified" : "Rejected"} successfully`
        );
        handleClose();
        updateMyData();
      })
      .catch((err) => {
        console.log("err", err);
        showToast(
          "error",
          "Error",
          "Something went wrong, please try after sometime."
        );
      });
  };

  const loaderBar = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        margin: "80px 0",
      }}
    >
      <ScaleLoader height={20} width={10} color={"#1c75bc"} />
    </div>
  );

  return (
    <Modal
      open={open}
      className={styles.profileVerificationModal}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
    >
      {
        <Box sx={style} className={styles.profileVerificationBox}>
          <DialogTitle disableTypography className={styles.header}>
            <span>{title}</span>
            <IconButton onClick={handleClose}>
              <Close />
            </IconButton>
          </DialogTitle>
          {modalLoader ? (
            loaderBar
          ) : dataList.aadharPhoto || dataList.profilePhoto ? (
            <>
              <DialogContent className={styles.userBox}>
                <div className={leftPicLink ? styles.imgDiv : styles.noImgDiv}>
                  <span>{leftPicName}</span>
                  <img
                    src={
                      uniqueKey === "profileVerification" && leftPicLink
                        ? `data:image/jpeg;base64,${dataList.aadharPhoto}`
                        : uniqueKey === "selfieVerification" && leftPicLink
                        ? leftPicLink
                        : NoRecord
                    }
                    alt={leftPicName}
                  ></img>
                  {!leftPicLink && <span>No data</span>}
                </div>
                <div className={rightPicLink ? styles.imgDiv : styles.noImgDiv}>
                  <span>{rightPicName}</span>
                  <img src={rightPicLink || NoRecord} alt={rightPicName}></img>
                  {!rightPicLink && <span>No data</span>}
                </div>
              </DialogContent>
              <DialogActions
                className={styles.userButton}
                style={{
                  display:
                    (uniqueKey === "profileVerification" &&
                      dataList.verification === 1) ||
                    (uniqueKey === "selfieVerification" &&
                      (dataList.attendanceVerification === 1 ||
                        dataList.attendanceVerification === 2))
                      ? "none"
                      : "flex",
                }}
              >
                <Button
                  size="small"
                  className="profileRejectBtn"
                  onClick={(e) => handleClick(e, 2)}
                >
                  Reject
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  className="profileApproveBtn"
                  onClick={(e) => handleClick(e, 1)}
                >
                  Approve
                </Button>
              </DialogActions>
            </>
          ) : (
            <DialogContent className={styles.noRecord}>
              <img src={NoRecord} alt="noRecord" style={{ height: "100px" }} />
              <span>No data</span>
            </DialogContent>
          )}
        </Box>
      }
    </Modal>
  );
}
