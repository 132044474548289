import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as actionCreators from "../../../store/actions/index";
import styles from "./Components.module.scss";
import { Button, Grid } from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import DateFilter from "../../../Utils/FilterComponents/DateFilter";
import ScaleLoader from "react-spinners/ScaleLoader";
import urlLinks from "../../../UrlLinks";
import SearchSelectFilter from "../../../Utils/FilterComponents/SearchSelectFilter";
import TimeFilter from "../../../Utils/FilterComponents/TimeFilter";
import AutoSelectFilter from "../../../Utils/FilterComponents/AutoSelectFilter";

const FilterDrawer = (props) => {
  const {
    handleClose,
    resetFilters,
    ApplyFilters,
    filters,
    leadCityId,
    setLeadCityId,
    setFilters,
    handleChange,
    selectedTime,
  } = props;
  const { users, applicationDb } = useSelector((state) => state);
  const { modalLoader } = users;
  const { curLeadId } = applicationDb;

  const dispatch = useDispatch();
  const { setCurLeadId } = bindActionCreators(actionCreators, dispatch);

  const selfieVerificationStatusList = [
    {
      id: 0,
      name: "Flagged",
    },
    {
      id: 1,
      name: "Verified",
    },
    {
      id: 2,
      name: "Rejected",
    },
    {
      id: 3,
      name: "Unverified",
    },
  ];

  const handleCancelFilters = () => {
    setLeadCityId(curLeadId.leadCity);
    handleClose();
  };

  const handleApplyFilters = () => {
    setCurLeadId(leadCityId);
    ApplyFilters();
    handleClose();
  };

  const loaderBar = (
    <div style={{ margin: "130px auto" }}>
      <ScaleLoader height={70} width={10} color={"#1c75bc"} />
    </div>
  );

  const output = modalLoader ? (
    <Grid container style={{ width: "520px", marginTop: "20px" }}>
      {loaderBar}
    </Grid>
  ) : (
    <Grid
      container
      style={{ width: "520px", marginTop: "20px" }}
      className={`${styles.filterContainerDrawer}`}
    >
      <Grid container className={`${styles.FilterHeadingContainer}`}>
        <div>
          <Button
            onClick={handleCancelFilters}
            className={styles.filterCloseArrow}
          >
            <ArrowForwardIcon />
          </Button>
          <span className={styles.filterHeading}>Filters</span>
        </div>
        <div>
          <Button onClick={resetFilters} className={styles.resetFiltersButton}>
            Reset
          </Button>
        </div>
      </Grid>
      <div className={styles.filterHeadingDivider}></div>
      <Grid container className={styles.filtersContainer}>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>City</span>
          <SearchSelectFilter
            filters={filters}
            setFilters={setFilters}
            id="city"
            sourceId={leadCityId}
            setSourceId={setLeadCityId}
            module="attendanceCityListing"
            multiple={true}
            placeholder="Search and select"
            url={`${urlLinks.baseUrl}${urlLinks.workerUrls.leadCities}`}
          />
        </Grid>
        <Grid container className={`${styles.singleFilterContainer}`}>
          <Grid item xs={5}>
            <span className={styles.filterTitle}>Date</span>
          </Grid>
          <Grid item xs={7}>
            <DateFilter
              filters={filters}
              setFilters={setFilters}
              labelTo="To"
              labelFrom="From"
              id="attendanceDate"
            />
          </Grid>
        </Grid>
        <Grid container className={`${styles.singleFilterContainer}`}>
          <Grid item xs={5}>
            <span className={styles.filterTitle}>Time</span>
          </Grid>
          <Grid item xs={7}>
            <TimeFilter
              uniqueKey="attendanceTime"
              timeRange={true}
              disabled={false}
              labelFrom="From"
              labelTo="To"
              ampm={true}
              val={selectedTime}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} className={`${styles.singleFilterContainer}`}>
          <span className={styles.filterTitle}>Selfie Verification Status</span>
          <AutoSelectFilter
            filters={filters}
            setFilters={setFilters}
            multipleFlag={false}
            id="selfieVerificationStatus"
            options={selfieVerificationStatusList}
          />
        </Grid>
      </Grid>
      <Grid container className={styles.filterButtonDividerContainer}>
        <div className={styles.filterHeadingDivider}></div>
        <Grid container style={{ marginTop: "30px" }} className="padding0">
          <div
            style={{
              paddingRight: "16px",
              paddingLeft: "300px",
            }}
          >
            <Button
              variant="outlined"
              onClick={handleCancelFilters}
              size="medium"
              style={{ color: "#1C75BC", borderColor: "#1C75BC" }}
            >
              Cancel
            </Button>
          </div>
          <Button
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleApplyFilters}
            style={{ backgroundColor: "#1C75BC" }}
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  return output;
};

export default FilterDrawer;
